import SelectionTagsListComponent from '@wix/thunderbolt-elements/src/components/SelectionTagsList/viewer/SelectionTagsList';
import SelectionTagsListController from '@wix/thunderbolt-elements/src/components/SelectionTagsList/viewer/SelectionTagsList.controller';


const SelectionTagsList = {
  component: SelectionTagsListComponent,
  controller: SelectionTagsListController
};


export const components = {
  ['SelectionTagsList']: SelectionTagsList
};

